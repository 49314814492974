import { useEffect, useRef } from 'react';

export const useHandleClickOutside = (callback: (event: MouseEvent) => void) => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClick = (event: MouseEvent) => {
      if (!ref.current?.contains(event.target as Node)) {
        callback(event);
      }
    };

    window.addEventListener('mousedown', handleClick, true);

    return () => {
      window.removeEventListener('mousedown', handleClick, true);
    };
  }, [callback, ref]);

  return ref;
};
