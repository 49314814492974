import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/app/lib/analytics/page-change.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/app/ui/components/atoms/dialog.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/app/ui/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["SpeedInsights"] */ "/vercel/path0/node_modules/@vercel/speed-insights/dist/next/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/google/target.css?{\"path\":\"app/ui/fonts.ts\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"],\"display\":\"swap\"}],\"variableName\":\"inter\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/google/target.css?{\"path\":\"app/ui/fonts.ts\",\"import\":\"Caveat\",\"arguments\":[{\"weight\":[\"700\"],\"subsets\":[\"latin\"],\"display\":\"swap\",\"preload\":false}],\"variableName\":\"caveat\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/local/target.css?{\"path\":\"app/ui/fonts.ts\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"../../public/fonts/Menlo-Regular.ttf\",\"weight\":\"400\",\"style\":\"normal\"}],\"display\":\"swap\",\"variable\":\"--font-menlo\",\"preload\":true}],\"variableName\":\"menlo\"}");
