'use client';

import { useDialogStore } from '@/app/lib/stores/dialog.store';
import { clsxMerge } from '@/app/lib/utils/styles';
import { useHandleClickOutside } from '@/app/ui/hooks/use-handle-click-outside';

export default function Dialog() {
  const { isDialogVisible, setIsDialogVisible, content } = useDialogStore();
  const ref = useHandleClickOutside(() => {
    if (isDialogVisible) {
      setIsDialogVisible(false);
    }
  });

  return (
    <div
      id='dialog'
      className={clsxMerge(
        'fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-20 transition-all duration-500 ease-in-out md:backdrop-blur-sm',
        { 'opacity-0 md:invisible': !isDialogVisible, 'translate-y-full md:translate-y-0': !isDialogVisible }
      )}
    >
      <div ref={ref} className='relative h-[calc(100dvh)] md:h-auto md:max-h-[90vh] md:max-w-[90vw]'>
        {content}
      </div>
    </div>
  );
}
