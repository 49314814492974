import { envs } from '@/app/lib/config/envs';
import CustomParams = Gtag.CustomParams;

type ComponentPreviewAction =
  | 'component_preview_mode_change'
  | 'component_preview_code_copy'
  | 'component_preview_storybook_open'
  | 'component_preview_github_source_open'
  | 'component_preview_figma_open';

interface GTagEvent {
  action: ComponentPreviewAction;
  category: 'user_interaction';
  label?: string;
  value?: number;
  nonInteraction?: boolean;
}

// https://developers.google.com/analytics/devguides/collection/gtagjs/events
export const event = ({ action, category, label, value = 1, nonInteraction }: GTagEvent): void => {
  const body: CustomParams = {
    event_category: category,
    value,
    ...(label ? { event_label: label } : {}),
    ...(nonInteraction !== undefined ? { non_interaction: nonInteraction } : {}),
  };

  if (!window.gtag) {
    console.log('Sending GA event', action, body);
  } else {
    window.gtag('event', action, body);
  }
};

export const pageview = (url: string) => {
  if (!window.gtag || !envs.googleAnalyticsMeasurementId) {
    console.log('GA pageview', url);
  } else {
    window.gtag('config', envs.googleAnalyticsMeasurementId, {
      page_path: url,
    });
  }
};
