import type { ReactNode } from 'react';
import { create } from 'zustand';

export interface DialogStore {
  isDialogVisible: boolean;
  setIsDialogVisible: (value: boolean) => void;
  content: ReactNode;
  setDialogContent: (content: ReactNode) => void;
}

export const useDialogStore = create<DialogStore>(set => ({
  isDialogVisible: false,
  setIsDialogVisible: isDialogVisible => {
    set({ isDialogVisible });
  },
  content: null,
  setDialogContent: content => {
    set({ content });
  },
}));
