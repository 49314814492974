'use client';

import { pageview } from '@/app/lib/analytics/ga';
import { usePathname } from 'next/navigation';
import { useEffect } from 'react';

export default function PageChange() {
  const pathname = usePathname();

  useEffect(() => {
    pageview(pathname);
  }, [pathname]);

  return null;
}
